.iphone {
  position: relative;
  margin: auto;
  height: 650px;
  width: 300px;
  border-radius: 32px;
  box-shadow: var(--neumorphic-box-shadow);
  padding: 8px;

  .iphone-notch {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 160px;
    margin: auto;
    background-color: var(--ion-background-color);
    height: 16px;
    border-radius: 0 0 16px 16px;
  }

  .iphone-screen {
    height: 100%;
    width: 100%;
    background-color: black;
    border-radius: 32px;

  }

}


.full-screen-modal {
  --width: 100%;
  --height: 98%;
  --border-radius: 0;
  padding-top: 2%;
}

.large-modal {
  @media screen and (min-width: 768px) {
    --width: 80%;
    --height: 80%;
  }
}


.plant-image {
  width: 120px;
  height: 120px;
  object-fit:cover;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  box-shadow: 4px 2px 16px rgba(0, 0, 0, 0.08),
  -4px -2px 16px rgba(238, 238, 238, 0.99);
  &.small{
    width: 60px;
    height: 60px;
    aspect-ratio: 1 / 1;

  }
}

.custom-emoji{
  font-size: 48px;
  line-height: auto;
  padding: 0;
  margin: auto;
  align-self: center;
}
